/**
 * Swiper base styles.
 */
@import "./settings/variables";
.swiper-container {
  position: relative;
  z-index: 1;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  width: 100%;
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
}

.swiper-slide {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 100%;

  &:nth-child(odd) {
    margin-top: 80px;
  }
  img {
    width: 80%;
    border-radius: 30px;
    -webkit-box-shadow: 0px 50px 50px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 50px 50px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px 50px 50px 0px rgba(0, 0, 0, 1);
  }
}

.swiper {
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
}

.swiper2 {
  width: 100%;
  margin-bottom: 160px;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
  .swiper-wrapper {
    padding-left: 16px;
  }
}
.swiper-slide2 {
  border-radius: 0px;
  .swiper-img {
    border-radius: 25px;
  }
  img {
    width: 100%;
    border-radius: 22px;
    @media (max-width: $lg) {
      border-radius: 35px;
    }
  }
  &:nth-child(odd) {
    margin-top: 0px;
  }
}
.swiper3 {
  margin-top: 13px;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
}
.swiper-wrapper3 {
  align-items: center;
}

.swiper-slide3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-left: 0;

  .swiper-box {
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }
  &:nth-child(odd) {
    margin-top: 0px;
  }
}

.swiper-big-image {
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
  .swiper-slide:nth-child(2n + 1) {
    margin-top: 220px;
    @media (max-width: $lg) {
      margin-top: 80px;
    }
  }
  &--reverse {
    .swiper-slide:nth-child(2n + 1) {
      margin-top: 0px;
    }
    .swiper-slide:nth-child(2n) {
      margin-top: 220px;
      @media (max-width: $lg) {
        margin-top: 80px;
      }
    }
  }
}
