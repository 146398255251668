.tk-mundial {
  font-family: Mundial, sans-serif;
}

.swiper-container {
  z-index: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.swiper-wrapper {
  z-index: 1;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  position: relative;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: relative;
}

.swiper-slide:nth-child(2n+1) {
  margin-top: 80px;
}

.swiper-slide img {
  width: 80%;
  border-radius: 30px;
  box-shadow: 0 50px 50px #000;
}

.swiper {
  display: none;
}

@media (max-width: 991px) {
  .swiper {
    display: block;
  }
}

.swiper2 {
  width: 100%;
  margin-bottom: 160px;
  display: none;
}

@media (max-width: 991px) {
  .swiper2 {
    display: block;
  }
}

.swiper2 .swiper-wrapper {
  padding-left: 16px;
}

.swiper-slide2 {
  border-radius: 0;
}

.swiper-slide2 .swiper-img {
  border-radius: 25px;
}

.swiper-slide2 img {
  width: 100%;
  border-radius: 22px;
}

@media (max-width: 991px) {
  .swiper-slide2 img {
    border-radius: 35px;
  }
}

.swiper-slide2:nth-child(2n+1) {
  margin-top: 0;
}

.swiper3 {
  margin-top: 13px;
  display: none;
}

@media (max-width: 991px) {
  .swiper3 {
    display: block;
  }
}

.swiper-wrapper3 {
  align-items: center;
}

.swiper-slide3 {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-left: 0;
  display: flex;
}

.swiper-slide3 .swiper-box {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  display: flex;
}

.swiper-slide3 .swiper-box img {
  width: 100%;
}

.swiper-slide3:nth-child(2n+1) {
  margin-top: 0;
}

.swiper-big-image .swiper-slide {
  justify-content: center;
  display: flex;
}

.swiper-big-image .swiper-slide:nth-child(2n+1) {
  margin-top: 220px;
}

@media (max-width: 991px) {
  .swiper-big-image .swiper-slide:nth-child(2n+1) {
    margin-top: 80px;
  }
}

.swiper-big-image--reverse .swiper-slide:nth-child(2n+1) {
  margin-top: 0;
}

.swiper-big-image--reverse .swiper-slide:nth-child(2n) {
  margin-top: 220px;
}

@media (max-width: 991px) {
  .swiper-big-image--reverse .swiper-slide:nth-child(2n) {
    margin-top: 80px;
  }
}

/*# sourceMappingURL=index.e2d5efa2.css.map */
